import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "../../components/account/Breadcrumbs";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { breadCrumbs, deployment2024, CFO } from "../../data/data";
import FilterDeployment from "../../components/modules/FilterDeployment";
import { selectedDeployment } from "../../data/staticData";

const spinnakerLogo =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/spinnaker-analytics-logo.webp";

const BusinessDashboards = () => {
  const { setPageTitle, userActivityTracker } = useContext(UserContext);
  const [deployment, setDeployment] = useState(
    localStorage.getItem("cfo-deployment-title") &&
      localStorage.getItem("cfo-deployment-value")
      ? {
          title: localStorage.getItem("cfo-deployment-title"),
          slug: localStorage.getItem("cfo-deployment-value"),
        }
      : {
          title: CFO.deployments[0].title,
          slug: CFO.deployments[0].slug,
        }
  );

  useEffect(() => {
    setPageTitle(CFO.title);
    userActivityTracker("Page changed", CFO.title);
  }, [deployment]);

  return (
    <section className="p-5 pt-0 w-full md:min-h-[35rem]">
      <div className="flex flex-col md:flex-row justify-between">
        <Breadcrumbs crumbs={breadCrumbs(CFO)} />
        {/* <FilterDeployment
          deployments={CFO.deployments}
          setDeployment={setDeployment}
          selected={selectedDeployment(deployment)}
          prefix={"cfo-"}
        /> */}
      </div>
      <div className="w-full my-10 pb-10 relative flex flex-col gap-20 transition-all duration-300 ease-in-out">
        <div className="md:px-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 h-full gap-5 transition-all duration-300 ease-in-out">
          {deployment?.title === deployment2024.title &&
            CFO.deployment2024?.map((module, index) => (
              <Link
                key={index}
                // to={"#"}
                to={CFO.slug + deployment2024.slug + module.slug}
                className="w-full h-full min-h-[10rem] p-10 flex flex-col gap-2 relative hover:text-white border hover:border-primary bg-primary bg-opacity-5 hover:bg-opacity-100 rounded-2xl shadow-lg"
              >
                <span className="absolute text-4xl top-2 right-3 opacity-10">
                  {CFO.icon}
                </span>
                {/* <div className="flex gap-2 items-center"> */}
                <div className="flex flex-col gap-2 items-center">
                  {/* <span className="p-1 rounded-full text-2xl w-fit"> */}
                  <span className="p-1 rounded-full text-3xl w-fit">
                    {module.icon}
                  </span>
                  {/* <h3 className="font-semibold text-sm">{module.title}</h3> */}
                  <h3 className="font-semibold text-base">{module.title}</h3>
                </div>
                <p className="">{module.description}</p>
              </Link>
            ))}
        </div>
      </div>
      {/* Powered By Spinnaker */}
      <div className="fixed hidden md:flex flex-col gap-10 right-5 bottom-5">
        <div className="w-full flex flex-col items-end scale-75 xl:scale-90">
          <p className="text-[10px] 2xl:text-xs">Powered by</p>
          <img
            src={spinnakerLogo}
            alt="powered by spinnaker analytics"
            className="w-28"
          />
        </div>
      </div>
    </section>
  );
};

export default BusinessDashboards;
