import React, { useContext } from "react";
import { colourStyles } from "../../utils/Functions";
import Select from "react-select";
import { createOptions } from "../../../data/staticData";
import {
  Classes,
  initial_model_input,
  inputScenarios,
  years,
} from "../../../data/modules/equityModel";
import { EquityModelContext } from "../../../context/equityModelContext";

const OutputHeader = () => {
  const {
    outputTypeToggle,
    setOutputTypeToggle,
    currentOutputTab,
    allOutputScenario,
    setCurrYear,
    scenarioComparisonOutputs,
    setScenarioComparisonOutputs,
    currYear,
  } = useContext(EquityModelContext);

  const createScenarioOptions = () => {
    const options = [];
    allOutputScenario.forEach((scenario, index) => {
      if (scenario.name !== "Baseline") {
        options.push({ label: scenario.name, value: index });
      }
    });
    return options;
  };

  const onChangeSelect = (e, mode) => {
    if (mode === "year") {
      setCurrYear(e.value);
    }

    if (mode === "scenario") {
      if (e.length === 0) {
        setScenarioComparisonOutputs([...allOutputScenario]);
      } else {
        const tempScenarios = [];
        e.map((object) => {
          allOutputScenario.forEach((scenario, index) => {
            if (object.value === index) {
              tempScenarios.push(scenario);
            }
          });
        });
        setScenarioComparisonOutputs([allOutputScenario[0], ...tempScenarios]);
      }
    }
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      const tempScenario = allOutputScenario.filter(
        (scenario) => scenario.name === value
      );
      console.log(tempScenario);
      setScenarioComparisonOutputs((prev) => [...prev, tempScenario[0]]);
    } else {
      const tempScenario = scenarioComparisonOutputs.filter(
        (scenario) => scenario.name != value
      );
      setScenarioComparisonOutputs(tempScenario);
    }
  };

  console.log(scenarioComparisonOutputs);

  return (
    <div className="flex gap-x-4 justify-between">
      <div className="flex gap-2 items-center">
        <div
          onClick={() => setOutputTypeToggle("poolValue")}
          className={`${
            outputTypeToggle === "poolValue"
              ? "bg-tertiary text-white"
              : "bg-bgPrimary text-black"
          } px-4 py-2 transition duration-300 ease-in-out hover:opacity-80 rounded-md cursor-pointer`}
        >
          {currentOutputTab === "Comparison"
            ? "Pool Value & CAGR"
            : "Pool Value"}
        </div>
        {currentOutputTab === "Scenario" && (
          <div
            onClick={() => setOutputTypeToggle("cagr")}
            className={`${
              outputTypeToggle === "cagr"
                ? "bg-tertiary text-white"
                : "bg-bgPrimary text-black"
            } px-4 py-2 transition duration-300 ease-in-out hover:opacity-80 rounded-md cursor-pointer`}
          >
            CAGR
          </div>
        )}
        {currentOutputTab === "Scenario" && (
          <div
            onClick={() => setOutputTypeToggle("engine")}
            className={`${
              outputTypeToggle === "engine"
                ? "bg-tertiary text-white"
                : "bg-bgPrimary text-black"
            } px-4 py-2 transition duration-300 ease-in-out hover:opacity-80 rounded-md cursor-pointer`}
          >
            Engine
          </div>
        )}

        {currentOutputTab === "Comparison" && (
          <div className="flex gap-2 items-center">
            <div
              onClick={() => setOutputTypeToggle("Dilution")}
              className={`${
                outputTypeToggle === "Dilution"
                  ? "bg-tertiary text-white"
                  : "bg-bgPrimary text-black"
              } px-4 py-2 transition duration-300 ease-in-out hover:opacity-80 rounded-md cursor-pointer`}
            >
              Dilution
            </div>
          </div>
        )}
      </div>

      {currentOutputTab === "Comparison" && (
        <div className="flex gap-x-4">
          <div className="flex gap-x-2 border text-xs rounded-md py-2 px-4">
            {inputScenarios
              .slice(1, inputScenarios.length)
              .map((scenario, index) => (
                <div key={index} className="flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    id={scenario}
                    name={scenario}
                    value={scenario}
                    checked={scenarioComparisonOutputs.map((object) => object.name).includes(scenario)}
                    onChange={handleCheckboxChange}
                    className="w-4 h-3"
                  />
                  <label htmlFor={scenario} className="mx-1">
                    {scenario}
                  </label>
                </div>
              ))}
          </div>
          <span className="flex items-center">
            <label htmlFor="select_year">Select Years:</label>
            <Select
              styles={colourStyles}
              name="select_year"
              id="select_year"
              options={createOptions(years.slice(1, years.length))}
              className="w-40"
              onChange={(e) => onChangeSelect(e, "year")}
              // defaultValue={createOptions(years.slice(1, years.length))[0]}
              defaultValue={{ label: currYear, value: currYear }}
            />
          </span>
        </div>
      )}
    </div>
  );
};

export default OutputHeader;
