// ***************************************************************  All Power BI Dashboards  ***************************************************************

export const dashboards = {
  // Territory Design
  businessDashboards: {
    masterDashboard: {
      deployment2024: {
        reportId: "2126d993-c64e-46ad-abef-2e858f69ce89",
      },
    },
    performanceDashboard: {
      deployment2024: {
        reportId: "d33108a0-0d2e-4d27-b3fe-61ce4be33120",
      },
    },
    foreqastTracking: {
      deployment2024: {
        reportId: "bd8aa512-3614-4338-b9ff-3e2d3ddc62b5",
      },
    },
    metricSummary: {
      deployment2024: {
        reportId: "7b937c6d-7443-4c67-a421-6aa2392e7e60",
      },
    },
    revenueDrillDown: {
      deployment2024: {
        reportId: "823e3301-a239-4168-bccc-4a91b4eb0c95",
      },
    },
    expenseDrillDown: {
      deployment2024: {
        reportId: "d4a98d9d-79a1-41fb-bb2d-ebedea90c3fd",
      },
    },
  },
};
