import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import axios from "axios";

// after auth
import Header from "./components/account/Header";
import ScrollToTop from "./components/utils/ScrollToTop";
import HomePage from "./pages/HomePage";
import Account from "./pages/account/Account";
// Misc
import NotFound from "./pages/NotFound";
import { UserContext } from "./context/userContext";
import jwtDecode from "jwt-decode";
import API from "./api/api";
import AuthPath from "./components/powerBIAuth/AuthPath";
import AuthTemplate from "./pages/auth/AuthTemplate";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { PowerBiPermissionScopes } from "./components/powerBIAuth/authConfig";

////////////////////////////////////////////////////////////////////// Solutions //////////////////////////////////////////////////////////////////////
import BusinessDashboards from "./pages/modules/BusinessDashboards";

////////////////////////////////////////////////////////////////////// Data //////////////////////////////////////////////////////////////////////
import { deployment2024, CFO, equityModel } from "./data/data";

////////////////////////////////////////////////////////////////////// Templates //////////////////////////////////////////////////////////////////////
import Deployment2024 from "./pages/deployments/Deployment2024";

import EquityModel from "./components/modules/EquityModel/EquityModel";
import { EquityModelContextProvider } from "./context/equityModelContext";

axios.defaults.withCredentials = true;

const clientLogo =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/client/assured_partners/ap-logo.webp";

const spinnakerIcon =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/spinnaker-analytics-icon-white.webp";

const spinnakerLogo =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/spinnaker-analytics-logo.webp";

function App() {
  const {
    isUserLoggedIn,
    setIsUserLoggedIn,
    setLoggedInUserData,
    setPbiAccessToken,
    pageTitle,
  } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  // Check if user session expired.
  useEffect(() => {}, [pageTitle]);

  useEffect(() => {
    setIsLoading(true);
    if (localStorage.getItem("ap_user_info")) {
      // If JWT token is present User is looged in.
      const accessToken = JSON.parse(
        localStorage.getItem("ap_user_info")
      ).accessToken;
      const decodedToken = jwtDecode(accessToken);
      if (decodedToken.exp * 1000 < new Date().getTime()) {
        localStorage.clear();
        setIsLoading(false);
        setIsUserLoggedIn(false);
        return;
      }
      setLoggedInUserData({
        company: decodedToken.company,
        fname: decodedToken.fname,
        lname: decodedToken.lname,
        username: decodedToken.username,
        email: decodedToken.email ?? null,
        role: decodedToken.role,
        phone: decodedToken.phone ?? null,
        picture: decodedToken.picture ?? null,
      });
      setIsUserLoggedIn(true);
      setIsLoading(false);
    } else {
      // If JWT Token is not present, the check for session logged in.
      const sessionToken = { session_token: "ap_user_info" };
      API.post("/auth-user-session", sessionToken)
        .then((response) => {
          if (response.status === 200 && response.data.userLoggedIn) {
            setIsUserLoggedIn(true);
            setLoggedInUserData({
              company: response.data.userInfo.company,
              username: response.data.userInfo.username,
              role: response.data.userInfo.role,
              fname: response.data.userInfo.fname,
              lname: response.data.userInfo.lname,
              id: response.data.userInfo.id,
              email: response.data.userInfo.email ?? null,
              phone: response.data.userInfo.phone ?? null,
              picture: response.data.userInfo.picture ?? null,
            });
            setIsLoading(false);
          }
        })
        .catch((error) => {
          // console.log(error);
          console.log("User not logged in");
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  const getAccessToken = async () => {
    const accessTokenRequest = {
      scopes: PowerBiPermissionScopes,
      account: account,
    };
    // before acquiring the token(or making any call to Msal API), first initialize and await the Msal Instance.
    await instance.initialize();

    // if user is logged-in into the dashboard atleast once in the current session then only acquire the token silently in the background.
    if (account) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((res) => {
          // console.log("Access Token Client (Refresh Token)\n", res);
          setPbiAccessToken(res.accessToken);
          localStorage.setItem("token_info", JSON.stringify(res));
        })
        .catch((err) => {
          // console.log("Refresh Token Error\n", err);
        });
    }
  };

  // Power BI token expired check
  const isTokenExpired = async () => {
    const tokenInfo = await JSON.parse(localStorage.getItem("token_info"));
    // console.log(tokenInfo);
    const expiryTime = new Date(tokenInfo.expiresOn);
    const currentTime = new Date();
    if (currentTime < expiryTime) {
      setPbiAccessToken(tokenInfo.accessToken);
      // console.log(tokenInfo);
    } else {
      getAccessToken();
    }
  };

  // Power BI refresh
  useEffect(() => {
    if (localStorage.getItem("token_info")) {
      isTokenExpired();
    } else {
      getAccessToken();
    }
  }, []);

  return (
    <>
      {!isLoading ? (
        <>
          <BrowserRouter>
            {isUserLoggedIn ? (
              <>
                {/* Pages only accessible for logged in users */}
                {/* Constant header */}
                <Header />
                <ScrollToTop />
                <main className="relative flex flex-col gap-2 transition-all duration-300 ease-in-out ml-12 scrollbar-hide">
                  {/* Routes */}
                  <Routes>
                    {/* Default */}
                    <Route
                      exact
                      path="/"
                      element={<Navigate to="/home" replace />}
                    ></Route>
                    {/* Home */}
                    <Route exact path="/home" element={<HomePage />} />
                    {/* Power BI auth path */}
                    <Route
                      exact
                      path="/microsoft/oauth2/v2.0/authorize"
                      element={<AuthPath />}
                    />
                    {/* User account routing */}
                    <Route exact path="/account/:slug" element={<Account />} />

                    {/*================================= Modules Routing Here  =================================*/}

                    {/* Business Dashboard */}
                    <Route exact path="/business-dashboard">
                      <Route exact path="" element={<BusinessDashboards />} />
                      <Route
                        exact
                        path="2024-deployment/:slug"
                        element={
                          <Deployment2024
                            solution={CFO}
                            deployment={deployment2024}
                          />
                        }
                      />
                    </Route>

                    {/* Equity Model */}
                    <Route exact path="/models">
                      
                      <Route
                        exact
                        path=":slug"
                        element={
                          <Deployment2024
                            solution={equityModel}
                            deployment={deployment2024}
                          />
                        }
                      />
                    </Route>

                    {/* Not Found page (All unknown routes) */}
                    <Route exact path="*" element={<NotFound />} />
                  </Routes>
                </main>
                {/* Show on small devices */}
                {/* <section className="fixed top-0 lg:hidden h-full w-full flex flex-col gap-5 items-center justify-center p-10 text-center text-[10px]">
                  <img src={clientLogo} alt="spinnaker logo" className="w-40" />
                  Looks like you are using or small screen. <br /> Please switch
                  to a laptop or a wider screen to use the portal. <br /> If you
                  are using an ipad or tablet switch to landscape mode.
                  
                  Powered By Spinnaker
                  <div className="w-full flex flex-col items-center scale-75 xl:scale-90">
                    <p className="text-[10px] 2xl:text-xs">Powered by</p>
                    <img
                      src={spinnakerLogo}
                      alt="powered by spinnaker analytics"
                      className="w-28"
                    />
                  </div>
                </section> */}
              </>
            ) : (
              <>
                {/* Pages Accessible only for not logged in users */}
                <Routes>
                  <Route exact path="/" element={<Navigate to="/login" />} />
                  <Route exact path="/login" element={<AuthTemplate />} />
                  <Route
                    exact
                    path="/forgot-password"
                    element={<AuthTemplate />}
                  />
                  <Route
                    exact
                    path="/request-an-account"
                    element={<AuthTemplate />}
                  />
                  <Route exact path="*" element={<NotFound />} />
                </Routes>
              </>
            )}
          </BrowserRouter>
        </>
      ) : (
        // Add a Loading Screen
        <>
          <div className="w-screen h-screen flex justify-center items-center">
            <span className="relative flex h-10 w-10">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-70"></span>
              <img src={spinnakerIcon} className="" alt="loader" />
            </span>
          </div>
        </>
      )}
    </>
  );
}

export default App;
