import React, { useContext, useEffect, useState } from "react";
import { Classes, Quaters, years } from "../../../data/modules/equityModel";
import { CurrencyFormatter, PercentFormatter1 } from "../../utils/Utils";
import { EquityModelContext } from "../../../context/equityModelContext";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const ScenarioComparision = () => {
  const { scenarioComparisonOutputs, currYear, outputTypeToggle } =
    useContext(EquityModelContext);
  return (
    <>
      <div className="mt-5 w-full">
        <div className="border shadow rounded-lg overflow-auto mb-5 w-fit max-h-[570px]">
          <table className={`w-fit text-xs`}>
            <thead className="bg-gray-400 sticky top-0">
              <tr>
                <th className="w-28 p-4">Class</th>
                <th className="w-28">Quater</th>
                <th className="">Information Type</th>

                {scenarioComparisonOutputs
                  .slice(outputTypeToggle.includes("Dilution") ? 1 : 0)
                  .map((scenario, index) => (
                    <th key={index} className="w-44">
                      <div className="flex gap-x-2 justify-center items-center">
                        <p>{scenario.name}</p>
                        <span data-tooltip-id={scenario.name}>
                          <AiOutlineInfoCircle className="cursor-pointer" />
                        </span>
                        <Tooltip
                          id={scenario.name}
                          place="left"
                          content={scenario.description}
                          variant="dark"
                          style={{
                            width: "fit-content",
                            fontSize: 10,
                            textAlign: "left",
                          }}
                        />
                      </div>

                      {outputTypeToggle === "poolValue" && (
                        <div className="flex gap-x-4 mt-1 justify-center">
                          {["Pool Value", "CAGR"].map((view, idx) => (
                            <p key={idx} className="">
                              {view}
                            </p>
                          ))}
                        </div>
                      )}
                      {outputTypeToggle === "Dilution" && (
                        <div className="flex gap-x-4 mt-1 justify-center">
                          {["Dilution ($)", "Dilution (%)"].map((view, idx) => (
                            <p key={idx} className="">
                              {view}
                            </p>
                          ))}
                        </div>
                      )}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {/* Total Class Row */}
              <tr className="font-bold border">
                <td className="py-4 text-center">Total Class</td>
                <td className="py-4 text-center"></td>
                <td className="py-4 text-center">
                  <div className="flex flex-col items-center justify-center gap-y-1">
                    {outputTypeToggle === "poolValue" && (
                      <>
                        <div className="">Available Amount</div>
                        <div className="">Outstanding Shares</div>
                      </>
                    )}
                    <div
                      className={`${
                        outputTypeToggle === "poolValue" && "border-t-2"
                      } w-fit`}
                    >
                      Per Unit Liquidation Value
                    </div>
                    <div>Available amount/Outstanding Shares</div>
                  </div>
                </td>
                {scenarioComparisonOutputs
                  .slice(
                    outputTypeToggle === "Dilution" ? 1 : 0,
                    scenarioComparisonOutputs.length
                  )
                  .map((scenario, idx) => (
                    <td className="py-4 text-center" key={idx}>
                      <div className="flex justify-center items-center">
                        {/* Total Class for Pool Value and CAGR*/}
                        {outputTypeToggle === "poolValue" &&
                          ["poolValue", "cagr"].map((item, i) => {
                            if (item === "poolValue") {
                              return (
                                <div className="flex flex-col gap-y-1 w-full">
                                  <div>
                                    {CurrencyFormatter(
                                      scenario["available_c_pool"][
                                        years.indexOf(currYear)
                                      ],
                                      1
                                    )}
                                  </div>
                                  <div>
                                    {CurrencyFormatter(
                                      scenario[
                                        "sub_total_all_c_vested_cummulative"
                                      ][years.indexOf(currYear)],
                                      1
                                    )}
                                  </div>
                                  <div className="border-t-2">
                                    {CurrencyFormatter(
                                      scenario["distribution_per_unit_c"][
                                        years.indexOf(currYear)
                                      ],
                                      2
                                    )}
                                  </div>
                                  <div className="">
                                    {CurrencyFormatter(
                                      scenario["available_c_pool"][
                                        years.indexOf(currYear)
                                      ] /
                                        scenario[
                                          "sub_total_all_c_vested_cummulative"
                                        ][years.indexOf(currYear)],
                                      2
                                    )}
                                  </div>
                                </div>
                              );
                            }

                            if (item === "cagr") {
                              return (
                                <div className="flex flex-col gap-y-1 mt-auto w-full">
                                  <div className="">
                                    {PercentFormatter1(
                                      (scenario["distribution_per_unit_c"][
                                        years.indexOf(currYear)
                                      ] /
                                        scenario[
                                          "distribution_per_unit_c"
                                        ][0]) **
                                        (1 / years.indexOf(currYear)) -
                                        1
                                    )}
                                  </div>
                                  <div className="">
                                    {PercentFormatter1(
                                      (scenario["available_c_pool"][
                                        years.indexOf(currYear)
                                      ] /
                                        scenario[
                                          "sub_total_all_c_vested_cummulative"
                                        ][years.indexOf(currYear)] /
                                        (scenario["available_c_pool"][0] /
                                          scenario[
                                            "sub_total_all_c_vested_cummulative"
                                          ][0])) **
                                        (1 / years.indexOf(currYear)) -
                                        1
                                    )}
                                  </div>
                                </div>
                              );
                            }
                          })}

                        {/* Total Class for Dilution $ and Dilution % */}
                        {outputTypeToggle === "Dilution" &&
                          ["Dilution ($)", "Dilution (%)"].map((view, i) => {
                            if (view === "Dilution ($)") {
                              return (
                                <div className="flex flex-col gap-y-1 w-full">
                                  <div>
                                    {CurrencyFormatter(
                                      scenarioComparisonOutputs[0][
                                        "distribution_per_unit_c"
                                      ][years.indexOf(currYear)] -
                                        scenario["distribution_per_unit_c"][
                                          years.indexOf(currYear)
                                        ],
                                      2
                                    )}
                                  </div>
                                  <div>
                                    {CurrencyFormatter(
                                      scenarioComparisonOutputs[0][
                                        "available_c_pool"
                                      ][years.indexOf(currYear)] /
                                        scenarioComparisonOutputs[0][
                                          "sub_total_all_c_vested_cummulative"
                                        ][years.indexOf(currYear)] -
                                        scenario["available_c_pool"][
                                          years.indexOf(currYear)
                                        ] /
                                          scenario[
                                            "sub_total_all_c_vested_cummulative"
                                          ][years.indexOf(currYear)],
                                      2
                                    )}
                                  </div>
                                </div>
                              );
                            }

                            if (view === "Dilution (%)") {
                              return (
                                <div className="flex flex-col gap-y-1 w-full">
                                  <div>
                                    {PercentFormatter1(
                                      (scenarioComparisonOutputs[0][
                                        "distribution_per_unit_c"
                                      ][years.indexOf(currYear)] -
                                        scenario["distribution_per_unit_c"][
                                          years.indexOf(currYear)
                                        ]) /
                                        scenarioComparisonOutputs[0][
                                          "distribution_per_unit_c"
                                        ][years.indexOf(currYear)]
                                    )}
                                  </div>
                                  <div>
                                    {PercentFormatter1(
                                      (scenarioComparisonOutputs[0][
                                        "available_c_pool"
                                      ][years.indexOf(currYear)] /
                                        scenarioComparisonOutputs[0][
                                          "sub_total_all_c_vested_cummulative"
                                        ][years.indexOf(currYear)] -
                                        scenario["available_c_pool"][
                                          years.indexOf(currYear)
                                        ] /
                                          scenario[
                                            "sub_total_all_c_vested_cummulative"
                                          ][years.indexOf(currYear)]) /
                                        (scenarioComparisonOutputs[0][
                                          "available_c_pool"
                                        ][years.indexOf(currYear)] /
                                          scenarioComparisonOutputs[0][
                                            "sub_total_all_c_vested_cummulative"
                                          ][years.indexOf(currYear)])
                                    )}
                                  </div>
                                </div>
                              );
                            }
                          })}
                      </div>
                    </td>
                  ))}
              </tr>

              {/* Individual Class */}
              {Classes.map((currClass, index) => (
                <tr key={index} className="border">
                  <td className="py-4 text-center">{currClass}</td>
                  <td className="py-4 text-center">
                    {Quaters[index] === "Q220"
                      ? "Q220 and earlier"
                      : Quaters[index]}
                  </td>
                  <td className="text-center py-3">
                    <div className="flex flex-col items-center justify-center">
                      <div className="">Available Amount</div>
                      {/* <div>Outstanding Shares</div> */}
                      <div className=" border-t-2 w-fit">
                        Per Unit Liquidation Value
                      </div>
                    </div>
                  </td>

                  {scenarioComparisonOutputs
                    .slice(
                      outputTypeToggle.includes("Dilution") ? 1 : 0,
                      scenarioComparisonOutputs.length
                    )
                    .map((scenario, idx) => (
                      <td className="text-center" key={idx}>
                        <div className="flex justify-center items-center">
                          {outputTypeToggle === "poolValue" &&
                            ["Pool Value", "CAGR"].map((view, i) => {
                              if (view === "Pool Value") {
                                return (
                                  <div className="flex flex-col w-full">
                                    <div>
                                      {CurrencyFormatter(
                                        scenario[
                                          "available_amt_per_unit_class_c"
                                        ][`${currClass}${Quaters[index]}`][
                                          years.indexOf(currYear)
                                        ],
                                        1
                                      )}
                                    </div>
                                    <div className="border-t-2">
                                      {CurrencyFormatter(
                                        scenario[
                                          "liquidation_value_per_unit_class"
                                        ][`${currClass}${Quaters[index]}`][
                                          years.indexOf(currYear)
                                        ],
                                        3
                                      )}
                                    </div>
                                  </div>
                                );
                              }

                              if (view === "CAGR") {
                                return (
                                  <div className="flex flex-col w-full">
                                    <div>
                                      {PercentFormatter1(
                                        scenario["available_amt_c_cagr"][
                                          `${currClass}${Quaters[index]}`
                                        ][years.indexOf(currYear) - 1],
                                        1
                                      )}
                                    </div>
                                    <div className="border-t-2">
                                      {PercentFormatter1(
                                        scenario["liquidation_cagr"][
                                          `${currClass}${Quaters[index]}`
                                        ][years.indexOf(currYear) - 1],
                                        1
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            })}

                          {outputTypeToggle === "Dilution" &&
                            ["Dilution ($)", "Dilution (%)"].map((view, i) => {
                              {
                                /* Class Wise Dilution in ($) */
                              }
                              if (view === "Dilution ($)") {
                                return (
                                  <div className="flex flex-col w-full">
                                    <div>
                                      {CurrencyFormatter(
                                        scenarioComparisonOutputs[0][
                                          "available_amt_per_unit_class_c"
                                        ][`${currClass}${Quaters[index]}`][
                                          years.indexOf(currYear)
                                        ] -
                                          scenario[
                                            "available_amt_per_unit_class_c"
                                          ][`${currClass}${Quaters[index]}`][
                                            years.indexOf(currYear)
                                          ],
                                        2
                                      )}
                                    </div>
                                    <div className="border-t-2">
                                      {CurrencyFormatter(
                                        scenarioComparisonOutputs[0][
                                          "liquidation_value_per_unit_class"
                                        ][`${currClass}${Quaters[index]}`][
                                          years.indexOf(currYear)
                                        ] -
                                          scenario[
                                            "liquidation_value_per_unit_class"
                                          ][`${currClass}${Quaters[index]}`][
                                            years.indexOf(currYear)
                                          ],
                                        2
                                      )}
                                    </div>
                                  </div>
                                );
                              }

                              if (view === "Dilution (%)") {
                                return (
                                  <div className="flex flex-col w-full">
                                    <div>
                                      {PercentFormatter1(
                                        (scenarioComparisonOutputs[0][
                                          "available_amt_per_unit_class_c"
                                        ][`${currClass}${Quaters[index]}`][
                                          years.indexOf(currYear)
                                        ] -
                                          scenario[
                                            "available_amt_per_unit_class_c"
                                          ][`${currClass}${Quaters[index]}`][
                                            years.indexOf(currYear)
                                          ]) /
                                          scenarioComparisonOutputs[0][
                                            "available_amt_per_unit_class_c"
                                          ][`${currClass}${Quaters[index]}`][
                                            years.indexOf(currYear)
                                          ]
                                      )}
                                    </div>
                                    <div className="border-t-2">
                                      {PercentFormatter1(
                                        (scenarioComparisonOutputs[0][
                                          "liquidation_value_per_unit_class"
                                        ][`${currClass}${Quaters[index]}`][
                                          years.indexOf(currYear)
                                        ] -
                                          scenario[
                                            "liquidation_value_per_unit_class"
                                          ][`${currClass}${Quaters[index]}`][
                                            years.indexOf(currYear)
                                          ]) /
                                          scenarioComparisonOutputs[0][
                                            "liquidation_value_per_unit_class"
                                          ][`${currClass}${Quaters[index]}`][
                                            years.indexOf(currYear)
                                          ]
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            })}
                        </div>
                      </td>
                    ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ScenarioComparision;
