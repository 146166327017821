import React, { useEffect, useState, useContext } from "react";
import ToggleSidebarButton from "./ToggleSidebarButton";
import InputScreen from "./InputScreen";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import API from "../../../api/api";
import ScenarioOutput from "./ScenarioOutput";
import ScenarioComparision from "./ScenarioComparision";
import { EquityModelContext } from "../../../context/equityModelContext";
import OutputHeader from "./OutputHeader";
import { inputScenarios } from "../../../data/modules/equityModel";
import { UserContext } from "../../../context/userContext";
import { EquityAllowedUsers } from "../../../data/data"

const EquityModel = () => {
  const {
    sidebar,
    currentOutputTab,
    setCurrentOutputTab,
    modelInputs,
    setModelInputs,
    allOutputScenario,
    setAllOutputScenario,
    currentScenarioIndex,
    setCurrentScenarioIndex,
    setShowOutput,
    setLoad,
    setOutputTypeToggle,
    setScenarioComparisonOutputs,
  } = useContext(EquityModelContext);
  const [checkPermission, setCheckPermission] = useState(false);

  const { loggedInUserData } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !EquityAllowedUsers.includes(loggedInUserData?.username)
    ) {
      setCheckPermission(false);
      // If user is not in the allowed user list return the user to home page
      navigate("/home");
    } else {
      setCheckPermission(true);
    }
  }, []);

  // handle backspace for percentages
  const handleBackspaceForPercentage = (e, index) => {
    if (e.key === "Backspace") {
      // Prevent the default backspace behavior
      e.preventDefault();

      // Get the current value of the input
      var inputValue = e.target.value;

      if (inputValue === "%") {
        changeState(e, "", index);
        return;
      }

      // Remove the last digit, keeping the '%' symbol intact
      if (inputValue.length > 0 && inputValue[inputValue.length - 2] !== "%") {
        if (inputValue.slice(0, -2) === "") {
          changeState(e, "", index);
          return;
        }
        // inputElement.value = inputValue.slice(0, -2) + "%";
        changeState(e, inputValue.slice(0, -2) + "", index);
      }
    }
  };

  // handle inputs with percentage
  const handlePercentageInputChange = (e, index) => {
    // setShowOutput(false);
    var inputValue = e.target.value;

    // Remove any non-numeric characters except the last '%'
    var numericValue = inputValue.replace(/[^0-9.]/g, "");

    // Count the number of decimal points
    var decimalCount = numericValue.split(".").length - 1;

    // Allow only one decimal point
    if (decimalCount > 1) {
      numericValue = numericValue.slice(0, numericValue.lastIndexOf("."));
    }

    // Limit the number of digits after the decimal point to two
    var parts = numericValue.split(".");
    if (parts[1] && parts[1].length > 2) {
      parts[1] = parts[1].slice(0, 2);
    }

    // Join the parts and add '%' symbol at the end of the numeric value if it's not empty
    var finalValue = parts.join(".") !== "" ? parts.join(".") + "" : "";

    // Set the updated value back to the input
    changeState(e, finalValue, index);
  };

  // Currency change inputs
  const handleCurrencyChange = (e, index) => {
    let inputValue = e.target.value;
    // Remove the leading $ sign if present
    if (inputValue.startsWith("$")) {
      inputValue = inputValue.slice(1);
    }
    // Allow only numbers and a single decimal point
    if (/^[0-9]*\.?[0-9]*$/.test(inputValue)) {
      // Remove leading zeros unless the value is '0' or '0.'
      inputValue = inputValue.replace(/^0+(?!\.|$)/, "");
      changeState(e, inputValue, index);
    }
  };

  // handle number inputs
  const handleNumberInputChange = (e, index) => {
    let inputValue = e.target.value.replace(/[^\d.]/g, "");
    // value = value.replace(/^0+\B(?=(\d{3})+(?!\d))/g, ",");
    // changeState(e, value, index);

    // Allow only numbers and a single decimal point
    if (/^[0-9]*\.?[0-9]*$/.test(inputValue)) {
      // Remove leading zeros unless the value is '0' or '0.'
      inputValue = inputValue.replace(/^0+(?!\.|$)/, "");
      changeState(e, inputValue, index);
    }
  };

  // Handle input description change.
  const handleDescriptionChange = (e, index) => {
    changeState(e, e.target.value, index);
  };

  const changeState = (e, value, index) => {
    setShowOutput(false);
    // setModelInputs((prev) => ({ ...prev, [e.target.name]: value }));
    setModelInputs((prev) => {
      return prev.map((item, idx) => {
        if (idx === index) {
          return { ...item, [e.target.name]: value };
        }
        return item;
      });
    });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setLoad(true);

    const percentageKeys = [
      "annual_revenue_growth",
      "class_A",
      "class_B",
      "class_D_target_value",
    ];

    const transformedData = modelInputs.map((item, index) => {
      const tempObject = {};
      for (let key in item) {
        const value = item[key];
        if (value instanceof String) {
          tempObject[key] = value;
          continue;
        }
        const parsedValue =
          value === undefined || value === null || value === ""
            ? 0
            : parseFloat(value);

        tempObject[key] = isNaN(parsedValue)
          ? 0
          : percentageKeys.includes(key)
          ? parsedValue / 100
          : parsedValue;
      }
      tempObject["scenarioName"] = inputScenarios[index];
      return tempObject;
    });

    const finalOutputData = await Promise.all(
      inputScenarios.map(async (scenario, index) => {
        try {
          const { data } = await API.post("/assuredpartners/equity-model", {
            data: transformedData[index],
          });
          return {
            ...data,
            name: scenario,
            description: modelInputs[index]["description"],
          };
        } catch (error) {
          console.log(error);
          return null;
        }
      })
    );

    // Filter Out the failed API calls
    // const filteredOutputData = finalOutputData.filter((item) => item !== null);
    const filteredOutputData = finalOutputData.filter((item) => {
      console.log(item);
      return item !== null;
    });
    console.log(filteredOutputData);
    setAllOutputScenario(filteredOutputData);
    setScenarioComparisonOutputs([...filteredOutputData]);
    setLoad(false);
    setShowOutput(true);
  };

  return (
    <>
      {checkPermission && (
        <div>
          <div className="flex gap-6 w-full h-full text-sm text-gray-700 relative">
            {/* Left SideBar containing Input and output Tabs */}
            <div className="w:1/5 xl:w-1/6 pr-2 pl-0 border-r relative">
              {/* Toggle Input & Output Tabs */}
              <ToggleSidebarButton />
              <div className="sticky top-24">
                {sidebar === "input" ? (
                  <>
                    <div
                      className={`py-2 px-3 text-xs w-full rounded-md cursor-pointer my-3 from-gray-600 via-gray-700 to-gray-800 hover:shadow ease-in-out hover:bg-gradient-to-r hover:text-white drop-shadow bg-gradient-to-r text-white`}
                    >
                      Model Input
                    </div>
                  </>
                ) : (
                  <>
                    {/* Output Left Side View */}
                    {allOutputScenario.length > 0 &&
                      allOutputScenario.map((item, index) => (
                        <div
                          onClick={() => {
                            setCurrentOutputTab("Scenario");
                            setCurrentScenarioIndex(index + 1);
                            setOutputTypeToggle("poolValue");
                          }}
                          className={`py-2 px-3 text-xs w-full rounded-md cursor-pointer my-2 from-gray-600 via-gray-700 to-gray-800 hover:shadow ease-in-out hover:bg-gradient-to-r hover:text-white drop-shadow ${
                            currentScenarioIndex === index + 1
                              ? "bg-gradient-to-r text-white"
                              : "bg-gray-100 text-gray-500"
                          }`}
                        >
                          {item.name}
                        </div>
                      ))}
                    {allOutputScenario.length > 1 &&
                      allOutputScenario.some(
                        (scenario) => scenario.name === "Baseline"
                      ) && (
                        <div
                          onClick={() => {
                            setCurrentOutputTab("Comparison");
                            setCurrentScenarioIndex(0);
                            setOutputTypeToggle("poolValue");
                            // setScenarioComparisonOutputs([ ...allOutputScenario ])
                          }}
                          className={`py-2 px-3 text-xs w-full rounded-md cursor-pointer my-2 from-gray-600 via-gray-700 to-gray-800 hover:shadow ease-in-out hover:bg-gradient-to-r hover:text-white drop-shadow ${
                            currentOutputTab === "Comparison"
                              ? "bg-gradient-to-r text-white"
                              : "bg-gray-100 text-gray-500"
                          }`}
                        >
                          Scenario Comparison
                        </div>
                      )}
                  </>
                )}
              </div>
            </div>

            {/* Entire Right Input and Output Screen */}
            <div className="w-full xl:w-5/6 h-full">
              {sidebar === "input" ? (
                <InputScreen
                  handleBackspaceForPercentage={handleBackspaceForPercentage}
                  handlePercentageInputChange={handlePercentageInputChange}
                  handleCurrencyChange={handleCurrencyChange}
                  handleNumberInputChange={handleNumberInputChange}
                  handleDescriptionChange={handleDescriptionChange}
                  submitForm={submitForm}
                />
              ) : (
                <>
                  {/* Output Screen */}
                  <OutputHeader />
                  {currentOutputTab === "Scenario" && (
                    <ScenarioOutput
                      outputData={allOutputScenario[currentScenarioIndex - 1]}
                    />
                  )}

                  {currentOutputTab === "Comparison" && <ScenarioComparision />}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EquityModel;
