import React, { useContext, useState } from "react";
import { UserContext } from "../../context/userContext";
import { MdOutlineEdit } from "react-icons/md";
import { FaCheck, FaRegEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { VscKey } from "react-icons/vsc";
import getUserData from "../utils/getUserData";
import { FaXmark } from "react-icons/fa6";
import API, { API_FILE } from "../../api/api";
import { LuLoader2 } from "react-icons/lu";

const ViewProfile = () => {
  const [enableEdit, setEnableEdit] = useState(false);
  const {
    loggedInUserData,
    setLoggedInUserData,
    setIsUserLoggedIn,
    userActivityTracker,
  } = useContext(UserContext);
  const initialInputData = {
    fname: loggedInUserData.fname || "",
    lname: loggedInUserData.lname || "",
    phone: loggedInUserData.phone || "",
    session_token: "ap_user_info",
  };
  const [inputData, setInputData] = useState(initialInputData);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [openImage, setOpenImage] = useState(false);
  const [load, setLoad] = useState(false);

  const handleInputChange = (e) => {
    e.preventDefault();

    if (e.target.name === "phone") {
      if (e.target.value.length < 11) {
        setInputData({
          ...inputData,
          [e.target.name]: parseInt(e.target.value),
        });
      }
    } else {
      setInputData({
        ...inputData,
        [e.target.name]: e.target.value,
      });
    }
  };

  // This arrow function would update the profile and generate new token for user
  const updateProfile = (e) => {
    e.preventDefault();
    API.post("/update-profile", inputData)
      .then(() => {
        // need to create a new token with updated user token if user has used remember me before
        getUserData(setLoggedInUserData, setIsUserLoggedIn);
        setSuccess("Profile updated successfully");
        userActivityTracker("User updated their profile", "Account Settings");
        setTimeout(() => {
          setSuccess(null);
        }, 5000);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const uploadImage = (e) => {
    e.preventDefault();
    setLoad(true);
    const size = e.target.files[0].size;
    // console.log(e.target.files);
    // 5000000 is 500KB
    if (size < 0.5 * 1024 * 1024) {
      const fileData = new FormData();
      fileData.append("image", e.target.files[0]);
      fileData.append("session_token", "ap_user_info");
      // axios.post(process.env.REACT_APP_API + "/upload-profile-picture", fileData)
      API_FILE.post("/upload-profile-picture", fileData)
        .then((res) => {
          if (res.data === null) {
            setError("Some error occured! Please try again later!");
            setTimeout(() => {
              setError(null);
            }, 5000);
          } else {
            getUserData(setLoggedInUserData, setIsUserLoggedIn);
            setSuccess("Picture updated successfully");
            setTimeout(() => {
              setSuccess(null);
            }, 5000);
          }
          userActivityTracker(
            "User updated their profile picture",
            "Account Settings"
          );
          setLoad(false);
          e.target = null;
        })
        .catch((err) => {
          setError("Some error occured! Please try again later!");
          setTimeout(() => {
            setError(null);
          }, 5000);
          setLoad(false);
          // console.log(err);
        });
    } else {
      setError("Max file size must be under 500KB");
      setTimeout(() => {
        setError(null);
      }, 5000);
      e.target = null;
      setLoad(false);
    }
  };

  return (
    <section className="md:p-10 flex flex-col md:flex-row justify-center w-full">
      <div className="w-fit">
        {error && (
          <div className="absolute w-full top-0 right-0 font-semibold flex gap-2 items-center justify-center text-white px-4 py-2 rounded-tr-lg rounded-tl-lg bg-red-600">
            <FaXmark />
            <span>{error}</span>
          </div>
        )}
        {success && (
          <div className="absolute w-full top-0 right-0 font-semibold flex gap-2 items-center justify-center text-white px-4 py-2 rounded-tr-lg rounded-tl-lg bg-green-600">
            <FaCheck />
            <span>{success}</span>
          </div>
        )}
        {/* Icon */}
        <div className="relative">
          {loggedInUserData.picture ? (
            // user icon
            <div
              className="w-40 h-40 md:w-52 md:h-52 rounded-full hover:opacity-80 cursor-pointer"
              title="view picture"
              onClick={() => setOpenImage(true)}
            >
              <img
                src={loggedInUserData.picture}
                alt="user"
                className="w-full h-full rounded-full object-cover p-1 ring-2 ring-gray-600"
              />
            </div>
          ) : (
            // user initials
            <div className="w-52 h-52 rounded-full ring-2 text-6xl ring-gray-400 pointer-events-none flex items-center justify-center bg-gray-100">
              {loggedInUserData.fname.substring(0, 1)}
              {loggedInUserData.lname.substring(0, 1)}
            </div>
          )}
          <div className="absolute cursor-pointer top-28 left-24 md:left-36 md:top-40 z-[1]">
            {load ? (
              <div>
                <label
                  htmlFor="file"
                  className="bg-white border cursor-wait border-gray-300 hover:drop-shadow px-4 py-1 rounded-lg flex gap-2 items-center"
                >
                  <LuLoader2 className="text-lg animate-spin" />
                  Uploading
                </label>
              </div>
            ) : (
              <form action="" encType="multipart/form-data">
                <input
                  type="file"
                  name="file"
                  id="file"
                  className="absolute opacity-0 w-0"
                  accept="image/png, image/webp, image/jpeg, image/jpg"
                  onChange={(e) => uploadImage(e)}
                />
                <label
                  htmlFor="file"
                  title="Max Upload size is 500KB"
                  className="bg-white border cursor-pointer border-gray-300 hover:drop-shadow px-4 py-1 rounded-lg flex gap-1 items-center"
                >
                  <MdOutlineEdit /> Edit
                </label>
              </form>
            )}
          </div>
        </div>

        {/* Company Details */}
        <div className="w-full overflow-hidden">
          {/* Names */}
          <div className="flex flex-col md:flex-row md:gap-10">
            {/* Company */}
            <div className="my-3 md:my-5 grid gap-2">
              <h3 className="capitalize tracking-wide text-gray-600 font-semibold text-sm">
                Company
              </h3>
              <p className="py-1">{loggedInUserData.company}</p>
            </div>
            {/* Username */}
            <div className="my-3 md:my-5 grid gap-2">
              <h3 className="capitalize tracking-wide text-gray-600 font-semibold text-sm">
                Username
              </h3>
              <p className="py-1 border-b-2 border-transparent">
                {loggedInUserData.username}
              </p>
            </div>
          </div>
          {/* Email */}
          <div className="mt-3 mb-5 md:my-5 grid gap-2">
            <h3 className="capitalize tracking-wide text-gray-600 font-semibold text-sm">
              Email
            </h3>
            <p className="py-1">{loggedInUserData.email}</p>
          </div>
        </div>
      </div>
      {/* Editable Data */}
      <div className="md:ml-10 md:px-10 md:border-l-2 border-gray-300 w-fit">
        <form onSubmit={(e) => updateProfile(e)}>
          {/* First Name */}
          <div className="grid gap-2">
            <h3 className="capitalize tracking-wide text-gray-600 font-semibold text-sm">
              First Name
            </h3>
            {enableEdit ? (
              <input
                type="text"
                name="fname"
                id="fname"
                value={inputData.fname || ""}
                className="outline-none py-1 border-b-2 border-gray-400"
                autoFocus={"ON"}
                onChange={(e) => handleInputChange(e)}
              />
            ) : (
              <p className="py-1 border-b-2 border-transparent">
                {loggedInUserData.fname}
              </p>
            )}
          </div>
          {/* Last Name */}
          <div className="my-5 grid gap-2">
            <h3 className="capitalize tracking-wide text-gray-600 font-semibold text-sm">
              Last Name
            </h3>
            {enableEdit ? (
              <input
                type="text"
                name="lname"
                id="lname"
                value={inputData.lname || ""}
                className="outline-none py-1 border-b-2 border-gray-400"
                autoFocus={"ON"}
                onChange={(e) => handleInputChange(e)}
              />
            ) : (
              <p className="py-1 border-b-2 border-transparent">
                {loggedInUserData.lname}
              </p>
            )}
          </div>
          {/* Phone */}
          <div className="my-5 grid gap-2">
            <h3 className="capitalize tracking-wide text-gray-600 font-semibold text-sm">
              Phone number
            </h3>
            {enableEdit ? (
              <input
                type="text"
                name="phone"
                id="phone"
                value={inputData.phone || ""}
                className="outline-none py-1 border-b-2 border-gray-400"
                autoFocus={"ON"}
                onChange={(e) => handleInputChange(e)}
              />
            ) : (
              <p className="py-1 border-b-2 border-transparent">
                {loggedInUserData.phone ? loggedInUserData.phone : "No contact"}
              </p>
            )}
          </div>
          {/* Action Buttons */}
          <div className="w-full">
            {enableEdit ? (
              <div className="flex gap-2">
                <button
                  onClick={(e) => {
                    setEnableEdit(false);
                    updateProfile(e);
                  }}
                  className="flex gap-2 items-center p-2 w-fit px-5 hover:bg-opacity-90 hover:drop-shadow-lg text-white justify-center rounded-lg bg-primary"
                >
                  Save Changes
                </button>
                <button
                  onClick={() => setEnableEdit(false)}
                  className="flex gap-2 items-center p-2 w-fit px-5 hover:bg-opacity-90 hover:drop-shadow-lg justify-center rounded-lg bg-gray-300"
                >
                  Cancel
                </button>
              </div>
            ) : (
              <button
                onClick={() => setEnableEdit(true)}
                className="flex gap-2 items-center p-2 w-fit px-5 hover:bg-opacity-90 hover:drop-shadow-lg justify-center rounded-lg bg-gray-100"
              >
                <FaRegEdit />
                Edit Profile
              </button>
            )}
          </div>
        </form>

        {/* Password */}
        <div className="mt-10 grid gap-2">
          <h3 className="capitalize tracking-wide text-gray-600 font-semibold text-sm">
            Security settings
          </h3>
          <Link
            to={"/account/change-password"}
            className="flex gap-2 items-center w-fit text-sm bg-gray-200 p-2 rounded-lg hover:drop-shadow"
          >
            <VscKey />
            Change Password
          </Link>
        </div>
      </div>

      {openImage && (
        <>
          <div
            className="fixed z-20 bottom-0 right-0 h-screen w-screen bg-black bg-opacity-20"
            onClick={() => setOpenImage(false)}
          ></div>
          <div className="fixed z-30 flex items-center top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
            <div className="relative">
              <div
                className="absolute top-5 right-5 cursor-pointer bg-gray-50 hover:bg-gray-200 px-2 py-1 rounded-lg"
                onClick={() => setOpenImage(false)}
              >
                <FaXmark />
              </div>
              <img
                src={loggedInUserData.picture}
                alt="user"
                className="w-fit h-96 rounded-lg object-fit p-1"
              />
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default ViewProfile;
