import React, { useContext, useEffect, useState } from "react";
import Tabs from "../../components/account/Tabs";
import { useParams } from "react-router-dom";
import NotFound from "../NotFound";
import Breadcrumbs from "../../components/account/Breadcrumbs";
import PowerBI from "../../components/powerBIAuth/PowerBI";
import { UserContext } from "../../context/userContext";
import { CFO, breadCrumbs } from "../../data/data";
import EquityModel from "../../components/modules/EquityModel/EquityModel";
import { EquityModelContextProvider } from "../../context/equityModelContext";

const Deployment2024 = ({ solution, deployment }) => {
  const { slug } = useParams();
  const [isSlugCorrect, setIsSlugCorrect] = useState(false);
  const [pageData, setPageData] = useState(null);
  const { setPageTitle, userActivityTracker } = useContext(UserContext);
  const [crumbs, setCrumbs] = useState(null);
  const [modules, setModules] = useState(null);

  useEffect(() => {
    solution.deployment2024.map((module) => {
      if (module?.slug.slice(1) === slug) {
        setModules(solution.deployment2024);
        setPageData(module);
        return setIsSlugCorrect(true);
      }
    });
  }, [slug]);

  useEffect(() => {
    // setPageTitle(`${deployment.title}: ${pageData?.title}`);
    setPageTitle(`${solution.title}: ${pageData?.title}`);
    userActivityTracker(
      "Page changed",
      `${solution.title}: ${pageData?.title}`
    );
    setCrumbs([
      ...breadCrumbs(solution),
      modules?.title !== solution?.title
        ? { title: modules?.title, title: pageData?.title }
        : { title: pageData?.title },
    ]);
  }, [pageData, deployment]);

  return (
    <>
      {/* Timeline Agnostic Template Only */}
      {isSlugCorrect && pageData ? (
        <section className="p-5 pt-0 w-full md:min-h-[35rem]">
          <div className="flex flex-col md:flex-row justify-between">
            <Breadcrumbs crumbs={crumbs} />
          </div>
          <div className="my-5">
            <Tabs data={modules} deployment={solution.slug + deployment.slug} />
          </div>
          <div className={`w-full pb-10 h-full md:min-h-[35rem]`}>
            {/* Territory Design 2024 deployments */}
            {CFO.deployment2024.map((module, index) => (
              <>
                {pageData.title === module.title && pageData.dashboards && (
                  <PowerBI
                    key={index}
                    reportId={pageData?.dashboards?.deployment2024?.reportId}
                    note={null}
                  />
                )}
              </>
            ))}

            {pageData.title === "Equity Model" && (
              <EquityModelContextProvider>
                <EquityModel />
              </EquityModelContextProvider>
            )}
          </div>
        </section>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default Deployment2024;
