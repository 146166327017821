// states
export const states = [
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
];

// months
export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// get Month
export const getMonth = (date) => {
  let month = "";
  const position = date.split("-")[1];
  month = months[Math.round(position) - 1];
  return month;
};

// sic codes
export const sicCodes = {
  Agriculture: [1, 999],
  Mining: [1000, 1499],
  Construction: [1500, 1999],
  Manufacturing: [2000, 3999],
  Transportation: [4000, 4799],
  "Communications & Utilities": [4800, 4999],
  "Wholesale Trade": [5000, 5199],
  Retail: [5200, 5999],
  "Financial Services": [6000, 6999],
  "Personal & Business Services": [7000, 7799],
  "Entertainment & Recreation": [7800, 7999],
  "Health Services": [8000, 8099],
  "Legal Services": [8100, 8199],
  "Education & Social Services": [8200, 8599],
  "Membership Organizations": [8600, 8699],
  "Professional Services": [8700, 8999],
  "Public Administration": [9000, 9998],
  "Non Classifiable": [9999],
};

// function to get industry by sic codes
export const getIndustryBySicCode = (number) => {
  let industry = "";
  Object.entries(sicCodes).forEach(([key, value]) => {
    if (value.length > 1) {
      if (number >= value[0] && number <= value[1]) {
        industry = key;
        return industry;
      }
    } else {
      if (number >= value[0]) {
        industry = key;
        return industry;
      }
    }
  });
  return industry;
};

export const bmi = (height, weight) => {
  const value = ((weight / (height * height)) * 703).toFixed(0);
  return value;
};

// function to assign bmi bin
export const bmiBin = (height, weight) => {
  let BMI = bmi(height, weight);
  let bmiBin = "";
  if (BMI <= 19) {
    bmiBin = "<19";
  } else if (BMI > 19 && BMI <= 22) {
    bmiBin = "19-22";
  } else if (BMI > 22 && BMI <= 25) {
    bmiBin = "22-25";
  } else if (BMI > 25 && BMI <= 30) {
    bmiBin = "25-30";
  } else {
    bmiBin = "30+";
  }

  return bmiBin;
};

// function to remove , from numbers
export const removeComma = (number) => {
  let parts = number.toString().includes(",")
    ? number.split(",").join("")
    : number;
  return parseInt(parts);
};

// function to assign age bin
export const ageBin = (age) => {
  let ageBin = "";
  if (age <= 20) {
    ageBin = "<20";
  } else if (age > 20 && age <= 29) {
    ageBin = "20-29";
  } else if (age >= 30 && age <= 39) {
    ageBin = "30-39";
  } else if (age >= 40 && age <= 49) {
    ageBin = "40-49";
  } else if (age >= 50 && age <= 54) {
    ageBin = "50-54";
  } else if (age >= 55 && age <= 59) {
    ageBin = "55-59";
  } else if (age >= 60 && age <= 64) {
    ageBin = "60-64";
  } else if (age >= 65 && age <= 69) {
    ageBin = "65-69";
  } else if (age >= 70 && age <= 79) {
    ageBin = "70-79";
  } else {
    ageBin = "80+";
  }
  return ageBin;
};

// function to create options out of arrays for react select
export const createOptions = (items) => {
  let array = [];
  let parts = "";
  for (let i = 0; i < items.length; i++) {
    if (!isNaN(items[i])) {
      parts = items[i];
    } else if (items[i].includes("&") === true) {
      parts = items[i].split(" & ").join(" and ");
    } else if (items[i].includes("'") === true) {
      parts = items[i].split("'").join("");
    } else {
      parts = items[i];
    }
    const object = { label: items[i], value: parts };
    array.push(object);
  }
  return array;
};

// function to create options out of arrays for react select for selecting deployments
export const createTimelineOptions = (items) => {
  let array = [];
  let parts = "";
  for (let i = 0; i < items?.length; i++) {
    parts = items[i].slug.split("/").join("");
    const object = {
      label: items[i].title,
      value: parts,
    };
    array.push(object);
  }
  // console.log(array);
  return array;
};

// selected industry label and values for react select
export const selectedDeployment = (item) => {
  let parts = item?.slug;
  return {
    label: item.title,
    value: parts,
  };
};
